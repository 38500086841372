import moment from 'moment';

const mapperDayOff: any = {
  VACATION: 'VACATION',
  SICK_LEAVE: 'SICK DAY',
  WEDDING: 'WEDDING',
  BIRTH_OF_CHILD: 'BIRTH OF CHILD',
  HALF_DAY: 'HALF DAY',
  DEATH_IN_FAMILY: 'DEATH IN FAMILY',
  SAINT_PATRONS_DAY: 'SAINT PATRONS DAY',
};

export const daysOffMapper = (daysOff: any) => {
  const mappedUserTechnologies = daysOff?.user?.userTechnologies
    ?.map((t: any) => t.technology.name)
    .join(', ');
  return {
    id: daysOff?.id,
    firstName: daysOff?.user?.firstName,
    lastName: daysOff?.user?.lastName,
    fullName: daysOff?.user?.displayName,
    email: daysOff?.user?.email,
    phoneNumber: daysOff?.user?.phoneNumber,
    vacationDays: daysOff?.user?.vacationDays,
    dateOfEmployment: daysOff?.user?.dateOfEmployment,
    startDate: daysOff?.days?.[0]?.day,
    endDate: daysOff?.days?.[daysOff?.days?.length - 1]?.day,
    days: daysOff?.days,
    reason: daysOff?.reason,
    userId: daysOff?.user?.id,
    link: daysOff?.user?.link,
    content: daysOff?.user?.content,
    contentType: daysOff?.user?.contentType,
    position: daysOff?.user?.position?.toLowerCase(),
    seniority: daysOff?.user?.seniority?.toLowerCase(),
    type: daysOff?.type,
    approved: daysOff?.approved,
    clients: daysOff?.clients,
    userTechnologies: mappedUserTechnologies,
  };
};

export const convertDate = (date: String | string | undefined) => {
  const year = date?.slice(0, 4) + '.';
  let month = date?.slice(5, 7) + '.';
  let day = date?.slice(8, 10) + '.';

  while (month?.charAt(0) === '0') {
    month = month?.substring(1);
  }

  while (day?.charAt(0) === '0') {
    day = day?.substring(1);
  }

  const convertedData = day + ' ' + month + ' ' + year;

  return convertedData;
};

export const unresolvedDaysOffMapper = (daysOff: any) => {
  const totalPages = daysOff?.totalPages;

  const content = daysOff?.content?.map((dayOff: any) => {
    let fromTo;
    if (dayOff?.days?.length === 1) {
      fromTo = moment(dayOff?.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
    } else {
      fromTo =
        moment(dayOff?.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY') +
        ' - ' +
        moment(dayOff?.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
    }

    return {
      userId: dayOff?.user?.id,
      id: dayOff?.id,
      requestedBy: dayOff?.displayName,
      approved: dayOff?.approved,
      fromTo,
      reason: dayOff?.reason,
      type: dayOff?.type,
      numberOfDays: dayOff?.numberOfDays,
      typeDisplay: mapperDayOff?.[dayOff?.type],
    };
  });

  return { totalPages, content };
};

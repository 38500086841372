import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Image,
  Text,
  HStack,
  useDisclosure,
  useToast,
  useMediaQuery,
  Grid,
  GridItem,
  ModalCloseButton,
  VStack,
  Spinner,
  Flex,
  Icon,
} from '@chakra-ui/react';
import BannerImage from '../../assets/images/banner.png';
import { PagesStyles } from '../../common/lib/chakraUI/baseStyles/PagesStyles';
import ErrorPage from '../../components/Layout/components/ErrorPage/ErrorPage';
import customNotification from '../../components/Notifications/fn/customNotification';
import { useWhatRole } from '../../common/hooks/useWhatRole';
import {
  useArchivePostMutation,
  useEditPollMutation,
  useGetPollDataQuery,
  useGetPostByIdQuery,
  useVotePollMutation,
} from '../../common/lib/redux/slices/apiSlices/postsApiSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import CommentSection from '../../pages/User/Details/components/CommentSection';
import ImageTypes from '../../pages/User/Details/PostComponents/ImageTypes';
import { mapColorCodeToChakraVar } from '../../helpers/formattingHelperFunctions';
import { convertDate } from '../../helpers/daysOffApiSlice';
import AddNewPostModal from '../../pages/User/Posts/Modal/AddNewPostModal';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import PollVotingComponent from '../Posts/VotingPoll';
import { useSelector } from 'react-redux';
import { RootState } from '../../common/lib/redux/store';
import { FaCopy } from 'react-icons/fa';
import { useCopyToClipboard } from '../../common/hooks/useCopyToClipboard';
import { useState } from 'react';

interface PostModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: number | null;
  postId?: any;
  isOpenFromDashboard?: boolean;
  postCommentsProp?: any;
  refetchAll?: any;
}

const DashboardViewPostModal = ({
  isOpen,
  onClose,
  id,
  postId,
  isOpenFromDashboard,
}: PostModalProps) => {
  const intl = useIntl();
  const {
    onOpen: onOpenAddEdit,
    onClose: onCloseAddEdit,
    isOpen: isOpenAddEdit,
  } = useDisclosure();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [copiedText, copyFn] = useCopyToClipboard();
  const currentUrl = isOpenFromDashboard
    ? window.location.href.slice(0, -9) + `posts/${postId}`
    : window.location.href;

  const [isHeightMoreThan778] = useMediaQuery('(min-height: 778px)');
  const { id: loggedInUserId } = useSelector((store: RootState) => store.user);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const {
    data: postsData,
    isLoading: isLoadingPostsData,
    isError: isErrorPostsData,
    refetch: postByIdRefetch,
  } = useGetPostByIdQuery({
    id: postId,
  });

  const { data: pollData } = useGetPollDataQuery({
    id: postId,
    userId: loggedInUserId,
  });

  const [archivePost, { isLoading: isLoadingArchivePost }] =
    useArchivePostMutation();

  const [votePoll] = useVotePollMutation();
  const [editPollOption, { isLoading: isLoadingEditPollOptions }] =
    useEditPollMutation();

  const role = useWhatRole();
  const toast = useToast();

  const handleVotePoll = (userId: number, optionId: number) => {
    votePoll({ userId, optionId })
      .then((res: any) => {
        if (res.data) {
          const message = res.data.message;
          customNotification(toast, 'success', message);
        }
      })
      .catch(() => {
        customNotification(toast, 'error', 'Error with voting for the poll!');
      });
  };

  const handleEditPollOption = (
    userId: number,
    oldOptionId: number,
    newOptionId: number,
  ) => {
    editPollOption({ userId, oldOptionId, newOptionId })
      .then((res: any) => {
        if (res.data) {
          const message = res.data.message;
          customNotification(toast, 'success', message);
        }
      })
      .catch(() => {
        customNotification(
          toast,
          'error',
          'Error with editing the poll option!',
        );
      });
  };

  const handleArchiveUnarchivePosts = (id: number | null | undefined) => {
    archivePost({ id })
      .then((res: any) => {
        if (res.data) {
          const message = res.data.message;
          customNotification(toast, 'success', message);
        }
      })
      .catch(() => {
        customNotification(
          toast,
          'error',
          'Error with Archiving or Unarchiving!',
        );
      });
  };

  const handleCopyToClipboard = () => {
    copyFn(currentUrl);
    customNotification(toast, 'success', 'Link copied to clipboard');
  };

  const { errorPage } = PagesStyles;

  if (isErrorPostsData) {
    return (
      <HStack {...errorPage}>
        <ErrorPage />
      </HStack>
    );
  }
  return (
    <ErrorBoundary>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="7xl"
        isCentered={isHeightMoreThan778 ? true : false}
        motionPreset="slideInBottom"
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent
          h="95vh"
          w={postsData?.assets.length > 0 ? '85vw' : '45vw'}
          maxW={postsData?.assets.length > 0 ? '1920px' : '1160px'}
          minW={postsData?.assets.length > 0 ? '1280px' : '500px'}
          mx="auto"
          maxH="90vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          rounded="xl"
        >
          <Grid
            h="full"
            w="full"
            templateColumns={postsData?.assets.length > 0 ? '2fr 1fr' : '1fr'}
          >
            {postsData?.assets.length > 0 ? (
              <GridItem colSpan={2 / 3} overflow="hidden">
                <ImageGallery
                  slides={postsData.assets}
                  isLoading={isLoadingPostsData}
                  withSmallImages={false}
                />
              </GridItem>
            ) : (
              ''
            )}
            <GridItem overflow="hidden">
              <Box
                borderLeft={
                  postsData?.assets.length > 0 ? '1px lightgray solid' : ''
                }
                boxSize="100%"
              >
                <ModalHeader p="0">
                  <Box>
                    <Image
                      src={BannerImage}
                      alt="banner image"
                      height="122px"
                      width="full"
                      objectFit={'none'}
                    />
                  </Box>
                  <Box
                    w="96%"
                    m="0 auto"
                    h="95px"
                    position="relative"
                    top="-50px"
                    bg={mapColorCodeToChakraVar('grey-200')}
                    borderRadius="15px"
                    display="flex"
                    alignItems="flex-start"
                    height="auto"
                  >
                    <Box position="relative" ml="4" mt="-30px">
                      <ImageTypes
                        width="6rem"
                        height="6rem"
                        boxHeight="6rem"
                        boxWidth="6rem"
                        postsData={postsData}
                      />
                    </Box>
                    <HStack
                      justifyContent="space-between"
                      boxSize="full"
                      mr="4"
                    >
                      <VStack
                        alignItems="flex-start"
                        ml="4"
                        flex="1"
                        alignSelf="start"
                      >
                        <VStack align="start" spacing={2}>
                          <Text
                            fontSize={{
                              base: '16px',
                              sm: '12px',
                              md: '12px',
                              lg: '14px',
                              xl: '16px',
                            }}
                            fontWeight="bold"
                            marginTop="11px"
                          >
                            <div>
                              {postsData?.title?.length > 30 && (
                                <button onClick={toggleExpand}>
                                  {isExpanded
                                    ? `${postsData?.title} `
                                    : `${postsData?.title?.slice(0, 30)}...`}
                                  <Text
                                    fontSize={{
                                      base: '12px',
                                      sm: '8px',
                                      md: '8px',
                                      lg: '10px',
                                      xl: '12px',
                                    }}
                                  >
                                    {isExpanded ? 'Show less' : 'Show more'}
                                  </Text>
                                </button>
                              )}
                            </div>
                          </Text>
                        </VStack>
                        <VStack>
                          {postsData?.createdDate ||
                          postsData?.startDate ||
                          postsData?.endDate ? (
                            <Text
                              fontSize={{
                                base: '12px',
                                sm: '8px',
                                md: '10px',
                                lg: '10px',
                                xl: '12px',
                              }}
                              fontWeight={postsData.startDate ? '600' : '400'}
                              fontFamily="Roboto"
                              color="grey.600"
                            >
                              {postsData?.postType?.type === 'POLL' ||
                              postsData?.postType?.type === 'EVENT' ? (
                                postsData.startDate === postsData.endDate ? (
                                  convertDate(postsData.startDate)
                                ) : postsData.startDate && postsData.endDate ? (
                                  <>
                                    {`${convertDate(postsData.startDate).slice(
                                      0,
                                      -5,
                                    )} - ${convertDate(postsData.endDate)}`}
                                  </>
                                ) : postsData.startDate ? (
                                  <>{convertDate(postsData.startDate)}</>
                                ) : (
                                  <>
                                    <FormattedMessage id={'POSTED_ON'} />
                                    {` ${convertDate(postsData.createdDate)}`}
                                  </>
                                )
                              ) : null}
                            </Text>
                          ) : (
                            ''
                          )}

                          {postsData?.archived ? (
                            <Text fontSize="12px" color="red.500">
                              ARCHIVED
                            </Text>
                          ) : (
                            <Box h="14px" />
                          )}
                        </VStack>
                      </VStack>
                      {!isLoadingPostsData && (
                        <HStack
                          // mt="25px"
                          h="full"
                          alignItems="flex-end"
                          flexDirection="column"
                          justifyContent="space-evenly"
                        >
                          <Flex align="center" gap="12px">
                            <Button
                              px={{
                                base: '3',
                                sm: '2',
                                md: '3',
                                lg: '3',
                                xl: '4',
                              }}
                              h="30px"
                              bg="white"
                              boxShadow="0px 2px 5.5px 0px #0000000F"
                              borderRadius="15px"
                              _hover={{
                                bg: 'white',
                                color: 'blue.500',
                              }}
                              display="flex"
                              gap="1"
                              onClick={handleCopyToClipboard}
                            >
                              {/* <Text
                                fontSize={{
                                  base: '12px',
                                  sm: '8px',
                                  md: '10px',
                                  lg: '10px',
                                  xl: '12px',
                                }}
                              >
                                {copiedText ? (
                                  <FormattedMessage id={'COPIED'} />
                                ) : (
                                  <FormattedMessage id={'COPY_LINK'} />
                                )}
                              </Text> */}
                              <Icon as={FaCopy} />
                            </Button>
                            {role !== 'EMPLOYEE' && (
                              <Button
                                px={{
                                  base: '3',
                                  sm: '2',
                                  md: '3',
                                  lg: '3',
                                  xl: '4',
                                }}
                                h="30px"
                                bg="white"
                                boxShadow="0px 2px 5.5px 0px #0000000F"
                                borderRadius="15px"
                                _hover={{
                                  bg: 'white',
                                  color: 'blue.500',
                                }}
                                disabled={
                                  isLoadingPostsData && isLoadingEditPollOptions
                                }
                                onClick={onOpenAddEdit}
                              >
                                <Text
                                  fontSize={{
                                    base: '12px',
                                    sm: '8px',
                                    md: '10px',
                                    lg: '10px',
                                    xl: '12px',
                                  }}
                                >
                                  <FormattedMessage id={'EDIT'} />
                                </Text>
                              </Button>
                            )}

                            {role !== 'EMPLOYEE' && (
                              <Button
                                px={{
                                  base: '3',
                                  sm: '2',
                                  md: '3',
                                  lg: '3',
                                  xl: '4',
                                }}
                                h="30px"
                                bg="red.500"
                                color="white"
                                boxShadow="0px 2px 5.5px 0px #0000000F"
                                borderRadius="15px"
                                _hover={{
                                  bg: 'red.700',
                                }}
                                disabled={isLoadingArchivePost}
                                onClick={() => handleArchiveUnarchivePosts(id)}
                              >
                                {isLoadingArchivePost ? (
                                  <Spinner />
                                ) : (
                                  <Text
                                    fontSize={{
                                      base: '12px',
                                      sm: '8px',
                                      md: '10px',
                                      lg: '10px',
                                      xl: '12px',
                                    }}
                                  >
                                    {postsData?.archived
                                      ? intl.formatMessage({ id: 'UNARCHIVE' })
                                      : intl.formatMessage({ id: 'ARCHIVE' })}
                                  </Text>
                                )}
                              </Button>
                            )}
                          </Flex>
                          {postsData?.createdBy && postsData?.createdDate && (
                            <Text
                              fontSize={11}
                              fontWeight={300}
                              textAlign="center"
                              color="grey.500"
                            >
                              Posted by: {postsData?.createdBy}{' '}
                              {convertDate(postsData?.createdDate)}
                            </Text>
                          )}
                        </HStack>
                      )}
                    </HStack>
                  </Box>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody
                  mt="-70px"
                  w="100%"
                  h={
                    postsData?.assets.length &&
                    postsData?.postType?.type.toLowerCase() !== 'poll'
                      ? `calc(100% - 105px)`
                      : `calc(100% - 205px)`
                  }
                >
                  <Box my="20px">
                    {postsData?.postType?.type.toLowerCase() === 'poll' && (
                      <PollVotingComponent
                        onSubmitVote={handleVotePoll}
                        onEditVote={handleEditPollOption}
                        options={pollData?.pollOptions}
                        userId={loggedInUserId}
                        endDate={postsData?.endDate}
                      />
                    )}
                  </Box>
                  <CommentSection
                    postId={Number(postId)}
                    isLoading={isLoadingPostsData}
                    text={postsData?.text}
                  />
                </ModalBody>
              </Box>
            </GridItem>
          </Grid>
        </ModalContent>
        {isOpenAddEdit ? (
          <AddNewPostModal
            isOpen={isOpenAddEdit}
            onClose={onCloseAddEdit}
            id={id}
            posts={pollData}
            refetchById={postByIdRefetch}
            type={postsData?.postType?.type.toLowerCase()}
          />
        ) : (
          ''
        )}
      </Modal>
    </ErrorBoundary>
  );
};

export default DashboardViewPostModal;
